// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink";

import { graphql } from "gatsby"

import lazySizes from 'lazysizes'

import "../styles/style.scss"

class Fashion extends Component {


    constructor(props) {
        super(props);
        this.modelViewerFashion = React.createRef();
    }

    state = {
        posterFashion: '',
        valueLoaded: '0'
    }

    componentDidMount() {
      if (typeof window !== `undefined`) {
          window.hello=() => {document.location.href = "mailto:hello@gaspardbruno.com";console.log("Send us an email!");return "";}
          setTimeout(console.log.bind(console, "Hello, you're interested in what's going on behind? We'd love to hear from you at " +   '%chello@gaspardbruno.com!' + '%c -> ' + '%chello()', 'color: #1812d6', 'color:black', 'font-weight:bold'), 0);
      }
      try {

        require('lazysizes/plugins/attrchange/ls.attrchange')
        require('lazysizes/plugins/unveilhooks/ls.unveilhooks.js')


        require("@google/model-viewer/dist/model-viewer")

        } catch (e) {
            console.error(e)
        }



    }


    red() {
        try {
            const modelViewerParameters = this.modelViewerFashion;
            const material = modelViewerParameters.current.model.materials[0];
            var url = ('/experimentation/tee-processed0.jpg');
            var url2 = ('/experimentation/tee-processed1.jpg');
            var url3 = ('/experimentation/tee-processed2.jpg');
            material.pbrMetallicRoughness.baseColorTexture.texture.source.setURI(url);
            material.pbrMetallicRoughness.metallicRoughnessTexture.texture.source.setURI(url2);
            material.normalTexture.texture.source.setURI(url3);
        } catch (e) {
          console.error(e)
        }
    }
    blue() {
        try {
            const modelViewerParameters = this.modelViewerFashion;
            const material = modelViewerParameters.current.model.materials[0];
            var url = ('/experimentation/base01.jpg');
            var url2 = ('/experimentation/metallicR01.jpg');
            var url3 = ('/experimentation/normal01.jpg');
            material.pbrMetallicRoughness.baseColorTexture.texture.source.setURI(url);
            material.pbrMetallicRoughness.metallicRoughnessTexture.texture.source.setURI(url2);
            material.normalTexture.texture.source.setURI(url3);
            material.pbrMetallicRoughness.setMetallicFactor([0]);
            material.pbrMetallicRoughness.setRoughnessFactor([0]);
        } catch (e) {
          console.error(e)
        }
    }
    dark() {
        try {
            const modelViewerParameters = this.modelViewerFashion;
            const material = modelViewerParameters.current.model.materials[0];
            var url = ('/experimentation/base03.jpg');
            var url2 = ('/experimentation/metallicR03.jpg');
            var url3 = ('/experimentation/normal03.jpg');
            material.pbrMetallicRoughness.baseColorTexture.texture.source.setURI(url);
            material.pbrMetallicRoughness.metallicRoughnessTexture.texture.source.setURI(url2);
            material.normalTexture.texture.source.setURI(url3);
            material.pbrMetallicRoughness.setMetallicFactor([0.5]);
            material.pbrMetallicRoughness.setRoughnessFactor([0.5]);
        } catch (e) {
          console.error(e)
        }
    }

    environment01() {
        try {
            this.modelViewerFashion.current.environmentImage = null;
        } catch (e) {
          console.error(e)
        }
    }
    environment02() {
        try {
            this.modelViewerFashion.current.environmentImage = '/experimentation/environment1.jpg';
        } catch (e) {
          console.error(e)
        }
    }




    render() {

        const data = this.props.data?.wpgraphql?.pages?.nodes[0]

        return (

        <Layout>

            <style>
            @import url('https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap');
            </style>

            <SEO title="Polygon Dynamics"/>

            <div className="fashionPage">

                <div className="row row___max marginSection customSection">
                    <div className="col col8">
                        <h3 className="titleSerif">Playground<br></br>Research & Development</h3>
                    </div>
                    <model-viewer ref={this.modelViewerFashion} camera-controls id="custom" min-field-of-view="59deg" max-field-of-view="59deg" min-camera-orbit="auto 91deg 0.84m" max-camera-orbit="auto 91deg 0.84m" auto-rotate interaction-policy="allow-when-focused" interaction-prompt-threshold="900" src="/experimentation/tee-processed.gltf"></model-viewer>
                    <div className="col col8">
                        <h3 className="title">POLYGON<br></br>DYNAMICS</h3>
                    </div>
                    <div className="buttonGroupExperiementationGroup">
                        <h3 className="LabelExperiementation">environment</h3>
                        <div className="buttonGroupExperiementation">
                                <button onClick={() => this.environment01()}>
                                        environment null
                                </button>
                                <button onClick={() => this.environment02()}>
                                        environment 02
                                </button>
                        </div>
                        <h3 className="LabelExperiementation">color</h3>
                        <div className="buttonGroupExperiementation">
                                <button onClick={() => this.red()}>
                                        red
                                </button>
                                <button onClick={() => this.blue()}>
                                        blue
                                </button>
                                <button onClick={() => this.dark()}>
                                        dark
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

        )
    }

}

export default Fashion


export const fashionQuery = graphql`
query {
    wpgraphql {
        pages(first: 1, where:{ title: "Fashion"} ) {
          nodes {
            title
            content
            fashion {
                videointro{
                    mediaItemUrl
                }
                image1{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image2{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image3{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image4{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image5{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
                image6{
                    sourceUrl
                    small: sourceUrl(size: SCOVER)
                    medium: sourceUrl(size: MCOVER)
                    large: sourceUrl(size: LCOVER)
                    xlarge: sourceUrl(size: XLCOVER)
                }
            }
          }
        }
    }
}
`
